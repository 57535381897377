import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from "react"
import Link from "next/link"
import { useTranslation } from "next-i18next"
import classNames from "classnames"
import { Field } from "rc-field-form"
import tw, { styled } from "twin.macro"

import Dropdown from "@components/v2/Dropdown"
import { css } from "@emotion/css"

import { Button } from "../Button"
import Icons from "../Icons"

import useCountryCodes from "./hooks/useCountryCodes"
import Input, { InputProps } from "./Input"

const CountryCodeWrapper = tw.div`
    flex gap-0.5
    cursor-pointer
    border-r border-grey-100 dark:border-dark-grey-100 pr-1
`
interface IconWrapperProps {
    isDropdownOpen: boolean
}
const LostPhoneLink = styled(Link)`
    ${tw`w-fit`}
`

const IconWrapper = styled.div<IconWrapperProps>`
    ${tw`flex items-center justify-center`}
    ${tw`transition-all duration-300`}
    ${({ isDropdownOpen }) => isDropdownOpen && tw`rotate-180`}
`

const Overlay = tw.div``
const SearchWrapper = tw.div`
    px-5 py-2
    border-b border-grey-200 dark:border-dark-grey-200
`

const SearchIconWrapper = tw.div`
    text-grey-500
    flex items-center justify-center
`

const ListItem = tw.div`
    w-full
    px-5 py-2
    flex justify-between

    cursor-pointer
    hover:(bg-primary text-white)
`

const ListItemCountry = tw.div`
    max-w-[300px] text-sm
`
const ListItemCode = tw.div`
    text-sm
`
const ListWrapper = tw.div`
    max-h-[250px]
    overflow-y-scroll
`

const StyledButton = styled(Button)`
    ${tw`h-full`}
`
interface CountryCodeProps {
    parentElement: MutableRefObject<HTMLDivElement>
    disabled?: boolean
}

const CountryCode: React.FC<CountryCodeProps> = ({ parentElement, disabled }) => {
    const [isOpen, setOpen] = useState(false)
    const { countryCodes } = useCountryCodes()
    const [search, setSearch] = useState("")

    const handleToggle = () => setOpen(!isOpen)
    const handleClose = () => setOpen(false)

    useEffect(() => {
        if (isOpen) {
            setSearch("")
        }
    }, [isOpen])

    const handleSelect = useCallback((cb: () => void) => {
        cb()
        handleClose()
    }, [])

    const renderedCountryCodes = useCallback(
        (onChange) =>
            countryCodes
                ?.filter(
                    (item) => item.country.toLowerCase().includes(search.toLowerCase()) || item.code.includes(search)
                )
                .map((item) => (
                    <ListItem onClick={() => handleSelect(() => onChange(item.code))} className='reku-new'>
                        <ListItemCountry>
                            <span>{item.country}</span>
                        </ListItemCountry>
                        <ListItemCode>{item.code}</ListItemCode>
                    </ListItem>
                )),
        [countryCodes, handleSelect, search]
    )

    return (
        <Field name='country_code' initialValue='+62'>
            {({ value, onChange }) => (
                <Dropdown
                    open={isOpen}
                    onClose={handleClose}
                    overlay={
                        <Overlay>
                            <SearchWrapper className='reku-new'>
                                <Input
                                    value={search}
                                    onChange={setSearch}
                                    groupClassName={css`
                                        ${tw`mb-0`}
                                    `}
                                    suffix={
                                        <SearchIconWrapper>
                                            <Icons icon='Search' width={22} height={22} />
                                        </SearchIconWrapper>
                                    }
                                />
                            </SearchWrapper>
                            <ListWrapper>{renderedCountryCodes(onChange)}</ListWrapper>
                        </Overlay>
                    }
                    parentElement={parentElement.current}
                >
                    <CountryCodeWrapper
                        onClick={handleToggle}
                        className={classNames("reku-new", {
                            "pointer-events-none text-disabled dark:text-dark-disabled": disabled
                        })}
                    >
                        {value}
                        <IconWrapper isDropdownOpen={isOpen}>
                            <Icons icon='ChevronDown' width={20} height={20} />
                        </IconWrapper>
                    </CountryCodeWrapper>
                </Dropdown>
            )}
        </Field>
    )
}

CountryCode.defaultProps = {
    disabled: false
}

export interface InputSendOTPProps extends InputProps {
    withLostPhone?: boolean
    /**
     * Can disable button
     */
    disabledButton?: boolean
    /**
     * action for button
     */
    onClick?: (value: unknown) => void
    loading?: boolean
}

const InputSendOTP: React.FC<InputSendOTPProps> = ({
    withLostPhone,
    disabledButton,
    disabled,
    size = "md",
    onClick,
    className,
    suffixClassName,
    loading,
    ...props
}) => {
    const { t } = useTranslation()
    const ref = useRef() as MutableRefObject<HTMLInputElement>

    return (
        <div>
            <Input
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                prefix={<CountryCode parentElement={ref} disabled={disabled} />}
                suffix={
                    <StyledButton
                        size={size}
                        disabled={disabled || disabledButton}
                        onClick={onClick}
                        type='button'
                        loading={loading}
                    >
                        {t("components:otp_input.sms.send_otp")}
                    </StyledButton>
                }
                inputWrapperRef={ref}
                mask={/^[0-9]\d{0,19}$/}
                className={classNames("relative h-full !overflow-visible", className)}
                inputClassName={classNames(
                    css`
                        ${tw`pl-2`}
                    `,
                    {
                        "!text-disabled dark:!text-dark-disabled": disabled
                    }
                )}
                suffixClassName={classNames("absolute -right-[2px] h-[105%] z-[1]", suffixClassName)}
                size={size}
                disabled={disabled}
            />
            {withLostPhone && (
                <LostPhoneLink href='/recoveryphone'>
                    <span tw='title-5'>{t("components:otp_input.lost_phone")}</span>
                </LostPhoneLink>
            )}
        </div>
    )
}

export default InputSendOTP
