/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useRef, useState } from "react"
import ReactDOM from "react-dom"
import OutsideClickHandler from "react-outside-click-handler"
import { Transition, TransitionStatus } from "react-transition-group"
import tw, { styled } from "twin.macro"

import { Else, If, Then } from "@components/If"
import { modalDialogClassName, modalOverlayClassName } from "@components/Modal/Modal"
import { css } from "@emotion/react"
import { isBrowser } from "@utils/browser"
import { getPageOffset } from "@utils/positions"

type Placement = "bottomLeft" | "bottomCenter" | "bottomRight"

interface DropdownRect {
    width: number | string
    top: number
    left?: number
    right?: number
    placement: Placement
}

interface AnimationProps {
    rect?: DropdownRect
    state: TransitionStatus
}

const Animation = styled.div<AnimationProps>`
    position: absolute;
    width: ${({ rect }) => (rect?.placement !== "bottomLeft" ? "max-content" : `${rect.width}px`)};
    /* width: max-content; */
    top: ${({ rect }) => `${rect?.top || 0}px`};
    left: ${({ rect }) => (rect?.left ? `${rect.left}px` : "unset")};
    right: ${({ rect }) => (rect?.right ? `${rect.right}px` : "unset")};

    transition: margin-top 100ms, opacity 100ms;

    margin-top: ${({ state }) => {
        switch (state) {
            case "entering":
                return "-10px"
            case "entered":
                return "0px"
            case "exiting":
                return "-10px"
            case "exited":
                return "-10px"
            default:
                return "-10px"
        }
    }};

    opacity: ${({ state }) => {
        switch (state) {
            case "entering":
                return 0
            case "entered":
                return 1
            case "exiting":
                return 0
            case "exited":
                return 0
            default:
                return 0
        }
    }};

    z-index: 99;
`

const StyledDropdown = styled.div<{ marginTop?: number }>`
    /* position: absolute;
    left: 0; */
    ${({ marginTop }) => marginTop && `margin-top: ${marginTop}px;`}

    ${({ marginTop }) => marginTop && `margin-top: ${marginTop}px;`}

    width: 100%;

    overflow: hidden;

    ${tw`bg-white dark:bg-background-dark-800 rounded-2xl border border-neutral-300 dark:border-background-dark-600`};
`

const ChildrenWrapper = styled.div``

const container = isBrowser ? document.createElement("div") : undefined

export interface DropdownProps extends React.PropsWithChildren<{}> {
    /**
     * Whether the dropdown is open or not
     */
    open?: boolean
    /**
     * Specify a function that will be called when a user clicks overlay and close button on top right
     */
    onClose?: () => void
    /**
     * The dropdown menu
     */
    overlay: React.ReactNode
    /**
     * Placement of popup menu
     */
    placement?: Placement
    /**
     * Passing props to dropdown menu
     */
    props?: Omit<React.HTMLProps<HTMLDivElement>, "as">
    /**
     * Parent HTML Element for dropdown position reference
     */
    parentElement?: HTMLElement
    /**
     * Using React Portal
     */
    usePortal?: boolean
    /**
     * margin top of dropdown
     * @default 0
     * */
    marginTop?: number
    /**
     * Class name for dropdown
     * */
    className?: string
    /**
     * Class name for wrapepr
     * */
    wrapperClassName?: string
}

const Dropdown: React.FC<DropdownProps> = ({
    open,
    onClose,
    overlay,
    placement,
    children,
    props,
    parentElement,
    usePortal,
    marginTop,
    className,
    wrapperClassName
}: DropdownProps) => {
    const nodeRef = useRef(null)
    const [rect, setRect] = useState<DropdownRect>()
    const childrenWrapperRef = useRef<HTMLDivElement | null>(null)

    const handlePosition = useCallback(() => {
        const pageOffset = getPageOffset()
        const parentRect = (parentElement || childrenWrapperRef?.current)?.getBoundingClientRect()

        if (parentRect) {
            if (usePortal) {
                let newRect: DropdownRect = {
                    width: parentRect.width,
                    top: pageOffset.y + parentRect.top + parentRect.height + 10,
                    placement: placement ?? "bottomLeft"
                }

                switch (placement) {
                    case "bottomLeft":
                        newRect = {
                            ...newRect,
                            left: pageOffset.x + parentRect.left
                        }
                        break
                    case "bottomCenter":
                        newRect = {
                            ...newRect,
                            left: pageOffset.x + parentRect.right - parentRect.width
                        }
                        break
                    case "bottomRight":
                        newRect = {
                            ...newRect,
                            right: document.documentElement.clientWidth - (pageOffset.x + parentRect.right)
                        }
                        break
                    default:
                        break
                }

                setRect(newRect)
            } else {
                const newRect: DropdownRect = {
                    width: parentRect?.width,
                    top: parentRect.height + 10,
                    placement: placement ?? "bottomLeft"
                }

                setRect(newRect)
            }
        }
    }, [parentElement, placement, usePortal])

    useEffect(() => {
        handlePosition()

        window.addEventListener("scroll", handlePosition)
        window.addEventListener("resize", handlePosition)

        const elModalOverlay = Array.from(document.getElementsByClassName(modalOverlayClassName))
        elModalOverlay?.forEach((el) => el.addEventListener("scroll", handlePosition))

        const elModalDialog = Array.from(document.getElementsByClassName(modalDialogClassName))

        const resizeObserver = new ResizeObserver(handlePosition)
        elModalDialog?.forEach((el) => resizeObserver.observe(el))

        const mutationObserver = new MutationObserver(handlePosition)
        elModalDialog?.forEach((el) =>
            mutationObserver.observe(el, { attributes: true, childList: true, subtree: true })
        )

        return () => {
            window.removeEventListener("scroll", handlePosition)
            window.removeEventListener("resize", handlePosition)

            elModalOverlay?.forEach((el) => el.removeEventListener("scroll", handlePosition))
            resizeObserver.disconnect()
            mutationObserver.disconnect()
        }
    }, [handlePosition])

    useEffect(() => {
        if (open) {
            if (container) {
                document.body.appendChild(container)
            }

            handlePosition()

            if (process.browser) {
                window.addEventListener("scroll", handlePosition)
                window.addEventListener("resize", handlePosition)
            }
        }

        return () => {
            if (open) {
                window.removeEventListener("scroll", handlePosition)
                window.removeEventListener("resize", handlePosition)
            }
        }
    }, [open, handlePosition])

    const handleOutsideClick = (e: MouseEvent) => {
        if (childrenWrapperRef.current?.contains(e.target as Node)) {
            return
        }

        onClose?.()
    }

    return (
        <>
            <If condition={usePortal}>
                <Then>
                    {container && (
                        <Transition in={open} timeout={100} unmountOnExit nodeRef={nodeRef}>
                            {(state) =>
                                ReactDOM.createPortal(
                                    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
                                        <Animation className={wrapperClassName} state={state} rect={rect}>
                                            <StyledDropdown className={className} marginTop={marginTop} {...props}>
                                                {overlay}
                                            </StyledDropdown>
                                        </Animation>
                                    </OutsideClickHandler>,
                                    container
                                )
                            }
                        </Transition>
                    )}
                </Then>
                <Else>
                    <div
                        css={css`
                            position: relative;
                        `}
                    >
                        <Transition in={open} timeout={100} unmountOnExit nodeRef={nodeRef}>
                            {(state) => (
                                <OutsideClickHandler onOutsideClick={handleOutsideClick}>
                                    <Animation className={wrapperClassName} state={state} rect={rect}>
                                        <StyledDropdown className={className} marginTop={marginTop} {...props}>
                                            {overlay}
                                        </StyledDropdown>
                                    </Animation>
                                </OutsideClickHandler>
                            )}
                        </Transition>
                    </div>
                </Else>
            </If>
            <ChildrenWrapper ref={childrenWrapperRef}>{children}</ChildrenWrapper>
        </>
    )
}

Dropdown.defaultProps = {
    open: false,
    onClose: () => {},
    placement: "bottomLeft",
    props: {},
    parentElement: undefined,
    usePortal: true,
    marginTop: undefined,
    className: undefined,
    wrapperClassName: undefined
}

export default Dropdown
