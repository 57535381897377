import { useEffect, useState } from "react"
import tw, { styled } from "twin.macro"

import Space from "@layouts/Space"

import { ButtonIcon } from "../Button"

import Input, { InputProps } from "./Input"

interface ComponentProps {
    size?: InputProps["size"]
    disabled?: boolean
}

const SwapperButton = styled(ButtonIcon)<ComponentProps>`
    ${tw`
        bg-foreground-teal dark:bg-dark-foreground-teal
        hover:bg-foreground-teal dark:hover:bg-dark-foreground-teal
        h-6 w-6 
        cursor-pointer select-none
    `}

    ${({ size }) => size === "sm" && tw`h-5 w-5`}
    ${({ disabled }) => disabled && tw`bg-background-strong dark:bg-dark-background-strong cursor-not-allowed`}

    svg {
        ${tw`
            text-icon-primary dark:text-dark-icon-primary
            w-4 h-4
        `}

        ${({ size }) => size === "sm" && tw`h-3 w-3`}
        ${({ disabled }) => disabled && tw`text-icon-disabled dark:text-dark-icon-disabled`}
    }
`

const SwapperText = styled.span<ComponentProps>`
    ${tw`
        paragraph-3
        text-bold dark:text-dark-bold
    `}

    ${({ size }) => size === "sm" && tw`paragraph-4`}
    ${({ disabled }) => disabled && tw`text-additional dark:text-dark-additional`}
`

interface Pair {
    from: string
    to: string
}

interface SwapperProps extends ComponentProps {
    pair: Pair
    onSwap?: () => void
}

const Swapper: React.FC<SwapperProps> = ({ pair, size, disabled, onSwap }: SwapperProps) => {
    const [swapItem, setSwapItem] = useState(pair)

    const handleSwap = () => {
        setSwapItem({
            from: swapItem.to,
            to: swapItem.from
        })

        onSwap?.()
    }

    useEffect(() => {
        setSwapItem(pair)
    }, [pair])

    return (
        <Space align='center'>
            <SwapperText size={size} disabled={disabled}>
                {swapItem.from}
            </SwapperText>
            <SwapperButton size={size} disabled={disabled} icon='SwitchHorizontal' type='button' onClick={handleSwap} />
            <SwapperText tw='text-disabled dark:text-dark-disabled' size={size} disabled={disabled}>
                {swapItem.to}
            </SwapperText>
        </Space>
    )
}

Swapper.defaultProps = {
    size: "md",
    disabled: false,
    onSwap: undefined
}

const StyledInput = tw(Input)`
    py-3 px-4
`

interface InputTradeProps extends Omit<InputProps, "suffix"> {
    /**
     * Pair item to swap
     * @required
     *
     * @example
     * pair: {
     *    from: "IDR",
     *    to: "BTC"
     * }
     */
    pair: Pair
    /**
     * Callback function when swap button clicked
     * @optional
     */
    onSwap?: () => void
}

const InputTrade: React.FC<InputTradeProps> = ({ size = "md", disabled, pair, onSwap, ...props }: InputTradeProps) => (
    <StyledInput
        mask={Number}
        size={size}
        disabled={disabled}
        suffix={<Swapper pair={pair} size={size} disabled={disabled} onSwap={onSwap} />}
        suffixClassName='!ml-2'
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
    />
)

InputTrade.defaultProps = {
    onSwap: undefined
}

export default InputTrade
