/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import tw, { styled } from "twin.macro"

import { IconsProps } from "@components/v3/Icons/Icons"

import { Button } from "../Button"

import Input, { InputProps } from "./Input"

const StyledButton = styled(Button)`
    ${tw`h-full`}
`

export interface ButtonProps extends InputProps {
    /**
     * Can disable button
     */
    disabledButton?: boolean
    /**
     * Custom icon
     */
    icon?: IconsProps["icon"]
}

export interface InputButtonProps extends React.PropsWithChildren<ButtonProps> {
    /**
     * action for button
     */
    onClick?: (value: unknown) => void
}

const InputButton: React.FC<InputButtonProps> = ({
    disabledButton,
    icon,
    size,
    suffixClassName,
    className,
    onClick,
    children,
    disabled,
    ...props
}: InputButtonProps) => (
    <Input
        className={`${className} relative h-full !overflow-visible`}
        size={size}
        suffix={
            <StyledButton
                icon={icon}
                size={size}
                iconPosition='trailing'
                disabled={disabled || disabledButton}
                onClick={onClick}
            >
                {children}
            </StyledButton>
        }
        disabled={disabled}
        suffixClassName={`${suffixClassName} absolute -right-[5px] h-[105%] z-[1]`}
        {...props}
    />
)

InputButton.defaultProps = {
    size: "sm"
}

export default InputButton
