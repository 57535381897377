import BigNumber from "bignumber.js"
import classNames from "classnames"
import tw, { styled } from "twin.macro"

import Icons from "@components/v3/Icons"

import Input, { InputProps } from "./Input"

interface ButtonProps {
    disabled?: boolean
}

const Button = styled.div<ButtonProps>`
    ${tw`flex items-center justify-center !border-none`}
    ${({ disabled }) => (disabled ? tw`cursor-not-allowed pointer-events-none` : tw`cursor-pointer`)}
`
interface SizeProps {
    /**
     * Set size
     * @default md
     *
     */
    size?: InputProps["size"]
}

const StyledIcons = styled(Icons)<SizeProps>`
    ${({ size }) => (size === "sm" ? tw`!w-5 !h-5` : tw`w-6 h-6`)}
    ${tw`text-icon-contrast dark:text-dark-icon-contrast`}
`
interface InputQuantityProps extends Omit<InputProps, "suffix" | "prefix"> {
    /**
     * Set movement for increase/decrease value
     */
    mov: number
    /**
     * Digits after comma (for Number Input)
     */
    scale: number
}

const InputQuantity: React.FC<InputQuantityProps> = ({
    value,
    onChange,
    mov,
    scale,
    size,
    disabled,
    min,
    max,
    ...props
}) => {
    const handleDecrease = () => {
        const newValue = new BigNumber(value || 0).minus(mov).toString()
        onChange?.(newValue)

        if (min !== undefined && parseFloat(newValue) < min) {
            onChange?.(String(min))
        }
    }

    const handleIncrease = () => {
        const newValue = new BigNumber(value || 0).plus(mov).toString()
        onChange?.(newValue)
        if (max !== undefined && parseFloat(newValue) > max) {
            onChange?.(String(max))
        }
    }

    const handleBlur = (blurValue: any) => {
        let newValue = new BigNumber(blurValue)
            .dividedBy(mov)
            .integerValue(BigNumber.ROUND_DOWN)
            .multipliedBy(mov)
            .toNumber()

        if (!newValue) {
            newValue = new BigNumber(blurValue)
                .dividedBy(mov)
                .integerValue(BigNumber.ROUND_UP)
                .multipliedBy(mov)
                .toNumber()
        }

        if (min !== undefined && newValue < min) {
            newValue = min
        }

        if (max !== undefined && newValue > max) {
            newValue = max
        }

        if (min !== undefined || max !== undefined) {
            onChange?.(String(newValue))
        }
    }

    return (
        <Input
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            value={value}
            onChange={onChange}
            mask={Number}
            scale={scale}
            size={size || "md"}
            min={min}
            max={max}
            disabled={disabled}
            prefix={
                <Button onClick={handleDecrease} disabled={disabled}>
                    <StyledIcons icon='Minus' size={size || "md"} />
                </Button>
            }
            prefixClassName={classNames({
                "pl-1": size === "sm"
            })}
            suffix={
                <Button onClick={handleIncrease} disabled={disabled}>
                    <StyledIcons icon='Plus' size={size || "md"} />
                </Button>
            }
            suffixClassName={classNames({
                "pr-1": size === "sm"
            })}
            onBlur={(blurValue) => {
                handleBlur(blurValue)
            }}
            groupClassName='mb-[15px]'
            inputClassName='text-center'
            onlyChangeOnFocus
        />
    )
}

InputQuantity.defaultProps = {
    min: 0
}

export default InputQuantity
