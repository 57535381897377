import { useEffect } from "react"

import { fetchCountryCodes } from "@redux/country-codes/actions"
import { useAppDispatch, useAppSelector } from "@redux/hooks"

const useCountryCodes = () => {
    const { countryCodes, isLoading } = useAppSelector((state) => state.countryCodes)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (!countryCodes) {
            dispatch(fetchCountryCodes())
        }
    }, [dispatch, countryCodes])

    return { countryCodes, isLoading }
}

export default useCountryCodes
